import {JsonSchema, UISchemaElement} from "@jsonforms/core";
import {RequirementsDto} from "../services/RequirementsService";

export interface Identity {
  pk: string,
  sk: string
}

export interface Auditable {
  createdAt: string,
  createdBy: {
    displayName: string,
    id: number
  }
}

export interface FormResponse {
  forms: FormDefinition[],
  schemas: Schema[],
  uiSchemas: UiSchema[]
}

export interface Schema extends Identity, Auditable {
  schema: JsonSchema
}

export interface UiSchema extends Identity, Auditable {
  uiSchema: UISchemaElement
  required?: string[];
}

export interface FormDetails {
  name: string,
  description: string,
  status: string,
  version: string,
  equipmentType: string
}

export interface FormDefinition extends Identity, Auditable, FormDetails {
  schema: Identity,
  ui: (FormRef | FormUiDef)[],
}

export interface RenderableForm extends Identity, Auditable, FormDetails {
  schema: any,
  uiSchema: any,
  required?: string[]
}

export enum FormDefinitionType {
  ref = "ref",
  uiSchema = "uiSchema",
  schema = "schema"
}

export interface FormRef {
  type: FormDefinitionType.ref,
  ref: Identity
}

export interface FormUiDef {
  type: FormDefinitionType.uiSchema,
  uiSchema: UISchemaElement,
  required?: string[]
}

