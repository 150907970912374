/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import MetaService from '../../common/services/MetaService';
import ReduxStore from '../../common/stores/redux/store';

const init = async () => {
    const requests = [
        MetaService.timezones(),
        MetaService.usStates(),
        MetaService.postalCodes(),
        MetaService.financial.terms(),
        MetaService.financial.statuses(),
        MetaService.account.opsAccounts(),
        MetaService.account.preferenceTypes(),
        MetaService.account.roles(),
        MetaService.account.departmentTypes(),
        MetaService.account.types(),
        MetaService.account.roleTypes(),
        MetaService.asset.assetStatuses(),
        MetaService.asset.photoTypes(),
        MetaService.asset.retirementReasons(),
        MetaService.client.clients(),
        MetaService.client.statuses(),
        MetaService.client.settings(),
        MetaService.client.freightTypes(),
        MetaService.client.businessChannels(),
        MetaService.client.integrationTypes(),
        MetaService.contractor.statuses(),
        MetaService.contractor.member.notifications(),
        MetaService.contractor.serviceTypes(),
        MetaService.contractor.certifications(),
        MetaService.contractor.credentials(),
        MetaService.contractor.blocklistReasons(),
        MetaService.contractor.qualificationTypes(),
        MetaService.document.types(),
        MetaService.document.tradeTypes(),
        MetaService.document.photoTypes(),
        MetaService.document.priorityTypes(),
        MetaService.document.contactTypes(),
        MetaService.document.fulfillmentTypes(),
        MetaService.document.attachmentTypes(),
        MetaService.document.workOrder.statuses(),
        MetaService.document.workOrder.types(),
        MetaService.document.quote.statuses(),
        MetaService.document.quote.types(),
        MetaService.document.quote.reasons(),
        MetaService.document.order.statuses(),
        MetaService.document.order.issues(),
        MetaService.document.order.types(),
        MetaService.document.order.contactTypes(),
        MetaService.document.order.notificationTypes(),
        MetaService.document.order.logisticsTypes(),
        MetaService.document.order.returnStatuses(),
        MetaService.document.order.returnReasons(),
        MetaService.document.order.returnCancelReasons(),
        MetaService.document.draft.statuses(),
        MetaService.product.categories(),
        MetaService.product.attributes(),
        MetaService.product.pricingTypes(),
        MetaService.product.unitOfMeasures(),
        MetaService.vendor.allVendor(),
        MetaService.vendor.vendors(),
        MetaService.vendor.manufacturers(),
        MetaService.vendor.statuses(),
        MetaService.vendor.integrationTypes(),
        MetaService.vendor.distributionCenter.dcConfigurations(),
        MetaService.property.propertyTypes(),
        MetaService.property.propertyStatuses(),
        MetaService.project.contactTypes(),
        MetaService.project.statuses(),
        MetaService.project.types(),
        MetaService.project.scopeTypes(),
        MetaService.ticket.providers(),
        MetaService.template.types(),
    ];

    const [
        timezones,
        usStates,
        postalCodes,
        financialTerms,
        financialStatuses,
        accountOpsAccounts,
        accountPreferenceTypes,
        accountRoles,
        accountDepartmentTypes,
        accountTypes,
        accountRolesTypes,
        assetStatues,
        assetPhotoTypes,
        assetRetirementReasons,
        clients,
        clientStatuses,
        clientSettings,
        clientFreightTypes,
        clientBusinessChannels,
        clientIntegrationTypes,
        contractorStatuses,
        contractorMemberNotifications,
        contractorServiceTypes,
        contractorCertifications,
        contractorCredentials,
        contractorBlocklistReasons,
        contractorQualificationTypes,
        documentTypes,
        documentTradeTypes,
        documentPhotoTypes,
        documentPriorityTypes,
        documentContactTypes,
        documentfulfillmentTypes,
        documentAttachmentTypes,
        workOrderStatuses,
        workOrderTypes,
        quoteStatuses,
        quoteTypes,
        quoteReasons,
        orderStatuses,
        orderIssues,
        orderTypes,
        orderContactTypes,
        orderNotificationTypes,
        orderLogisticsTypes,
        orderReturnStatuses,
        orderReturnReasons,
        orderReturnCancelReasons,
        draftStatuses,
        productCategories,
        productAttributes,
        productPricingTypes,
        productUnitOfMeasures,
        vendorAllVendor,
        vendorVendors,
        vendorManufacturers,
        vendorStatuses,
        vendorIntegrationTypes,
        vendorDistributionCenterDcConfigurations,
        propertyTypes,
        propertyStatuses,
        projectContactTypes,
        projectStatuses,
        projectTypes,
        projectScopeTypes,
        ticketProviders,
        templateTypes,
    ] = await Promise.all(requests);

    return {
        timezones,
        usStates,
        postalCodes,
        financial: {
            terms: financialTerms,
            statuses: financialStatuses,
        },
        account: {
            opsAccounts: accountOpsAccounts,
            preferenceTypes: accountPreferenceTypes,
            roles: accountRoles,
            departmentTypes: accountDepartmentTypes,
            types: accountTypes,
            roleTypes: accountRolesTypes,
        },
        asset: {
            assetStatuses: assetStatues,
            photoTypes: assetPhotoTypes,
            retirementReasons: assetRetirementReasons,
        },
        client: {
            clients: clients,
            statuses: clientStatuses,
            settings: clientSettings,
            freightTypes: clientFreightTypes,
            businessChannels: clientBusinessChannels,
            integrationTypes: clientIntegrationTypes,
        },
        contractor: {
            statuses: contractorStatuses,
            qualificationTypes: contractorQualificationTypes,
            member: {
                notifications: contractorMemberNotifications,
            },
            serviceTypes: contractorServiceTypes,
            certifications: contractorCertifications,
            credentials: contractorCredentials,
            // clients: [],
            blocklistReasons: contractorBlocklistReasons,
        },
        document: {
            types: documentTypes,
            tradeTypes: documentTradeTypes,
            photoTypes: documentPhotoTypes,
            priorityTypes: documentPriorityTypes,
            contactTypes: documentContactTypes,
            fulfillmentTypes: documentfulfillmentTypes,
            attachmentTypes: documentAttachmentTypes,
            workOrder: {
                statuses: workOrderStatuses,
                types: workOrderTypes,
            },
            quote: {
                statuses: quoteStatuses,
                types: quoteTypes,
                reasons: quoteReasons,
            },
            order: {
                statuses: orderStatuses,
                types: orderTypes,
                contactTypes: orderContactTypes,
                notificationTypes: orderNotificationTypes,
                logisticsTypes: orderLogisticsTypes,
                returnStatuses: orderReturnStatuses,
                returnReasons: orderReturnReasons,
                returnCancelReasons: orderReturnCancelReasons,
                issues: orderIssues,
            },
            draft: {
                statuses: draftStatuses,
            },
        },
        product: {
            categories: productCategories,
            attributes: productAttributes,
            pricingTypes: productPricingTypes,
            unitOfMeasures: productUnitOfMeasures,
        },
        project: {
            contactTypes: projectContactTypes,
            statuses: projectStatuses,
            types: projectTypes,
            scopeTypes: projectScopeTypes,
        },
        property: {
            types: propertyTypes,
            statuses: propertyStatuses,
        },
        template: {
            types: templateTypes,
        },
        ticket: {
            providers: ticketProviders,
        },
        vendor: {
            allVendor: vendorAllVendor,
            vendors: vendorVendors,
            manufacturers: vendorManufacturers,
            statuses: vendorStatuses,
            integrationTypes: vendorIntegrationTypes,
            distributionCenter: {
                configurations: vendorDistributionCenterDcConfigurations,
            },
        },
    };
};

const refresh = async () => {
    const accountOpsAccounts = await MetaService.account.opsAccounts();
    const clients = await MetaService.client.clients();
    const currentState = ReduxStore.getState().metaStore.metaData;
    return {
        account: {
            opsAccounts: accountOpsAccounts,
            preferenceTypes: currentState.account.preferenceTypes,
            roles: currentState.account.roles,
            departmentTypes: currentState.account.departmentTypes,
            types: currentState.account.types,
            roleTypes: currentState.account.roleTypes,
        },
        client: {
            clients: clients,
            statuses: currentState.client.statuses,
            settings: currentState.client.settings,
            freightTypes: currentState.client.freightTypes,
            businessChannels: currentState.client.businessChannels,
            integrationTypes: currentState.client.integrationTypes,
        },
    };
};

export default { init, refresh };
