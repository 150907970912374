import superagent, {SuperAgentRequest} from 'superagent';
import Config from "Config";
import {logout} from "./AuthService";
import {FormResponse} from "../types/requirements";

const baseUrl = Config.requirementsBuilderApi;
const TOKEN_MISSING = "TokenMissing";

export interface RequirementsDto {
  deviceId?: string;
  workOrderId: number;
  propertyId: number;
  clientId: number;
  assetId: number;
  assetType: string;
  version: string;
  data: object;
}

export interface RequirementsResponse {
  data: RequirementsDto[];
  dependencies: FormResponse;
}

const parseStatusFromResponseOrError = (unknownResponse: unknown) => {
  let status;
  let message;
  if(unknownResponse instanceof Response){
    status = unknownResponse.status as number;
    message = unknownResponse.statusText;
  }
  else if (unknownResponse instanceof Error) {
    if(unknownResponse.name === TOKEN_MISSING){
      status = 403;
      message = unknownResponse.message;
    } else {
      status = 500;
      message = unknownResponse.message;
    }
  }
  return [status || 500, message];
}

const getAuthToken = () => {
  const token = localStorage.getItem(`${Config.storageNameSpace}.token`);
  if(!token){
    const error = Error("User is not logged in");
    error.name = TOKEN_MISSING;
    throw error;
  }
  return JSON.parse(token);
}

const fetchAllSchemaDefs = async ():Promise<FormResponse> => {
  return new Promise ((resolve, reject) =>
    withRequiredHeaders(superagent.get(`${baseUrl}/forms`))
    .end((err, res) => {
      if(err) {
        const [status, message] = parseStatusFromResponseOrError(err);
        maybeLogout(status as number);
        return reject(new Error(message as string));
      } else {
        return resolve(res.body);
      }
    })
  )
}

const saveRequirements = async (requirements: RequirementsDto):Promise<RequirementsResponse> => {
  return new Promise ((resolve, reject) =>
    withRequiredHeaders(superagent.post(`${baseUrl}/requirements`))
      .send(requirements)
      .end((err, res) => {
      if(err) {
        const [status, message] = parseStatusFromResponseOrError(err);
        maybeLogout(status as number);
        return reject(new Error(message as string));
      } else {
        return resolve(res.body);
      }
    })
  )
}

const fetchRequirementsForWorkOrder = async (workOrderId: number):Promise<RequirementsResponse> => {
  return new Promise ((resolve, reject) =>
    withRequiredHeaders(superagent.get(`${baseUrl}/requirements/${workOrderId}`))
      .end((err, res) => {
        if(err) {
          const [status, message] = parseStatusFromResponseOrError(err);
          maybeLogout(status as number);
          return reject(new Error(message as string));
        } else {
          return resolve(res.body);
        }
      })
  )
}


const withRequiredHeaders = (req: SuperAgentRequest) => {
  const token = getAuthToken();
  return req
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('jwtid', token.id)
    .set('userid', token.userId)
}

const maybeLogout = (status: number) => {
  if([401, 403].find(httpStatus => httpStatus === status)) {
    logout();
  }
}

export default {
  fetchAllSchemaDefs,
  saveRequirements,
  fetchRequirementsForWorkOrder
}

