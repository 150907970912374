/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import * as Validator from '../utils/Validator';
import { get, patch as _patch, put, post, del } from './ApiService';
import * as ApiService from './ApiService';

const baseUrl = 'clients';

/**
 *
 * @param {*} id
 * @param {*} query
 * @returns {Object}
 */
export function findById(id, query) {
    return get(true, `${baseUrl}/${id}`, query);
}

/**
 * @param {Object} data
 * @param {Object} data.filter
 * @returns {Promise}
 */
export function find(data) {
    return post(true, `${baseUrl}/search`, data);
}

/**
 * Search client hierarchy
 * @param {Object} data
 * @param {Object} data.filter
 * @returns {Promise}
 */
export function findHierarchy(data) {
    return post(true, `${baseUrl}/searchHierarchy`, data);
}

/**
 *
 * @param {Object} data
 * @param {Object} data.where
 * @returns {Object}
 */
export function count(data) {
    return post(true, `${baseUrl}/search/count`, data);
}

/**
 * invokes POST /api/Clients
 * @param {object} client
 * @param {object} [query]
 * @returns {Promise<Object>}
 */
export function create(client, query) {
    return post(true, `${baseUrl}`, client, query);
}

/**
 *
 * @param {*} id
 * @param {*} client
 * @returns {Object}
 */
export function patch(id, client) {
    return _patch(true, `${baseUrl}/${id}`, client);
}

/**
 *
 * @param {*} id
 * @param {*} address
 * @returns {Object}
 */
export function updateAddress(id, address) {
    return put(true, `${baseUrl}/${id}/address`, address);
}

/**
 *
 * @param {*} clientId
 * @param {*} accountId
 * @returns {Object}
 */
export function activateTeamMember(clientId, accountId) {
    return post(true, `${baseUrl}/${clientId}/team/${accountId}/activate`);
}

/**
 *
 * @param {*} clientId
 * @param {*} accountId
 * @returns {Object}
 */
export function deactivateTeamMember(clientId, accountId) {
    return post(true, `${baseUrl}/${clientId}/team/${accountId}/deActivate`);
}

/**
 *
 * @param {*} clientId
 * @param {*} data
 * @returns {Object}
 */
export function createTeamMember(clientId, data) {
    return post(true, `${baseUrl}/${clientId}/newTeam`, data);
}

/**
 *
 * @param {*} clientId
 * @param {*} data
 * @returns {Object}
 */
export function updateTeamMember(clientId, data) {
    return post(true, `${baseUrl}/${clientId}/editTeam`, data);
}

/**
 * Create a new note for the client
 * @param {*} clientId
 * @param {*} data
 * @returns {Object}
 */
export function createNote(clientId, data) {
    return post(true, `${baseUrl}/${clientId}/notes`, data);
}

/**
 * Update a note for the client
 * @param {*} clientId
 * @param {*} noteId
 * @param {Object} data
 *
 * @returns {Object}
 */
export function updateNote(clientId, noteId, data) {
    return put(true, `${baseUrl}/${clientId}/notes/${noteId}`, data);
}

export function deleteBlockListItem(id, clientId) {
    return del(`${baseUrl}/${clientId}/blacklists/${id}`);
}

export function addBlockListItem(clientId, contractorId, reasonId) {
    return post(true, `${baseUrl}/${clientId}/blacklists`, {
        reasonId,
        contractorId,
    });
}

export async function editBlocklistItem(id, clientId, selectedReason) {
    return put(true, `${baseUrl}/${clientId}/blacklists/${id}`, {
        reasonId: selectedReason,
    });
}

export function updateNotificationPreferences(clientId, preferenceId, data) {
    return put(
        true,
        `${baseUrl}/${clientId}/notification-preferences/${preferenceId}`,
        data
    );
}

/**
 *
 * @param {number} clientId
 * @param {object} requirement
 * @returns {Promise<Object>}
 */
export function createRequirement(clientId, requirement) {
    return post(true, `${baseUrl}/${clientId}/requirements`, requirement);
}

/**
 *
 * @param {number} clientId
 * @param {number} requirementId
 * @param {object} requirement
 * @returns {Promise<Object>}
 */
export function updateRequirement(clientId, requirementId, requirement) {
    return put(
        true,
        `${baseUrl}/${clientId}/requirements/${requirementId}`,
        requirement
    );
}

/**
 *
 * @param {number} clientId
 * @param {number} requirementId
 * @returns {Promise<Object>}
 */
export function deleteRequirement(clientId, requirementId) {
    return del(`${baseUrl}/${clientId}/requirements/${requirementId}`);
}

export function inheritedPhotoRequirement(clientId, inheritedPhotoRequirement) {
    const inheritedPhoto = { inheritedPhotoRequirement: inheritedPhotoRequirement };
    return post(true, `${baseUrl}/${clientId}/inheritedPhotoRequirement`, inheritedPhoto);
}

export function createAgent(clientId, agent) {
    return post(true, `${baseUrl}/${clientId}/agents`, agent);
}

export function updateAgent(clientId, agentId, agent) {
    return put(true, `${baseUrl}/${clientId}/agents/${agentId}`, agent);
}

export function settingTypes(id, query) {
    return get(true, `${baseUrl}/settingTypes`);
}

/**
 *
 * @param {number} clientId
 * @param {number} settingId
 * @param {object} setting
 * @returns {Promise<Object>}
 */
export function updateSetting(clientId, settingId, setting) {
    return put(true, `${baseUrl}/${clientId}/settings/${settingId}`, setting);
}

/**
 *
 * @param {number} clientId
 * @param {object} setting
 * @returns {Promise<Object>}
 */
export function createSetting(clientId, setting) {
    return post(true, `${baseUrl}/${clientId}/settings`, setting);
}

/**
 *
 * @param {number} clientId
 * @param {object} integration
 * @returns {Promise<Object>}
 */
export function createIntegration(clientId, integration) {
    return post(true, `${baseUrl}/${clientId}/integrations`, integration);
}

/**
 *
 * @param {number} clientId
 * @param {string} integrationId
 * @param {object} integration
 * @returns {Promise<Object>}
 */
export function updateIntegration(clientId, integrationId, integration) {
    return put(
        true,
        `${baseUrl}/${clientId}/integrations/${integrationId}`,
        integration
    );
}

/**
 *
 * @param {number} clientId
 * @param {string} integrationId
 * @returns {Promise<Object>}
 */
export function deleteIntegration(clientId, integrationId) {
    return del(`${baseUrl}/${clientId}/integrations/${integrationId}`);
}

export function approve(clientId, reason, childrenIds) {
    return post(true, `${baseUrl}/${clientId}/approve`, childrenIds, {
        reason: reason,
    });
}

export function deactivate(clientId, reason, childrenIds) {
    return post(true, `${baseUrl}/${clientId}/deactivate/`, childrenIds, {
        reason: reason,
    });
}

export function hold(clientId, reason, childrenIds) {
    return post(true, `${baseUrl}/${clientId}/hold/`, childrenIds, {
        reason: reason,
    });
}

export function pending(clientId, reason, childrenIds) {
    return post(true, `${baseUrl}/${clientId}/pending/`, childrenIds, {
        reason: reason,
    });
}

export function enable(clientId, reason, childrenIds) {
    return post(true, `${baseUrl}/${clientId}/enable/`, childrenIds, {
        reason: reason,
    });
}

export function isValidAddress(address) {
    if (address.standardized && !address.stdAddress) {
        return false;
    }
    if (address.standardized === false) {
        if (!address.stdDeliveryLine) {
            return false;
        }
        if (!address.stdCity) {
            return false;
        }
        if (!address.stdRegion) {
            return false;
        }
        if (!address.stdPostalCode) {
            return false;
        }
        if (!address.timezone) {
            return false;
        }
        if (!Validator.isPostalCode(address.stdPostalCode)) {
            return false;
        }
    }
    return true;
}

/**
 * synchronously validates a work order contact object
 * @param {object} contact
 * @returns {boolean}
 */
export function isValidContact(contact) {
    const { type, name, email, mobile, phone } = contact;
    if (!type) {
        return false;
    }
    if (!name) {
        return false;
    }
    if (name && !Validator.isMax80Characters(name)) {
        return false;
    }
    if (!email && !mobile && !phone) {
        return false;
    }
    if (
        email &&
        (!Validator.isEmail(email) || !Validator.isMultipleEmail(email))
    ) {
        return false;
    }
    if (mobile && !Validator.isPhone(mobile)) {
        return false;
    }
    if (phone && !Validator.isPhone(phone)) {
        return false;
    }
    return true;
}

export function getOnHoldRelateDocument(id, includeSubClient = false) {
    return get(true, `${baseUrl}/${id}/hold/documents`, { includeSubClient });
}

export function mapClientRequirementsToPhotoRequirements(clientRequirements) {
    /*
        [{
            clientId
            typeId
            tradeTypeId
            tradeSubTypeId
            workOrderTypeId
            priorityId
            clientClipValue
            percentMargin
            contractorClipValue
            photoTypeId
            photoRequirementTypeId -- 'OPTIONAL' || 'REQUIRED'
        }]
    */
}

export function mapPhotoRequirementsToClientRequirements(photoRequirements) {
    /*
        [{
            photoTypeId,
            photoTypeDisplay,
            workOrderTypes: [{id, display}],
            tradeAndSubTypes: [{id, display}],
            required
        }]
    */
}

// export function buildTradeAndSubTypesThing(tradeTypes) {
//     const tradeAndSubTypes = tradeTypes
//         .reduce((acc, next) => {
//             const tradeAndSubTypesForTradeType = map(next.tradeSubTypes, subType => ({
//                 id: `${next.id}-${subType.id}`,
//                 display: `${next.display} - ${subType.display}`,
//                 tradeTypeId: next.id,
//                 tradeSubTypeId: subType.id
//             }));
//             return acc.concat(tradeAndSubTypesForTradeType);
//         }, []);
//     return tradeAndSubTypes;
// }

export function createBom(clientId, bom) {
    return post(true, `${baseUrl}/${clientId}/boms`, bom);
}

export function findBoms(clientId, search) {
    return get(true, `${baseUrl}/${clientId}/boms`, { search });
}

export function findBomSuggest(clientId, value, additionalFilter) {
    return get(true, `${baseUrl}/${clientId}/boms/suggest`, {
        prefix: value,
        minPrefexLength: undefined,
        limit: undefined,
        existingOnly: true,
        additionalFilter,
    });
}

export function deactivateBom(clientId, bomId) {
    return get(true, `${baseUrl}/${clientId}/boms/${bomId}/deactivate`);
}

export function findBom(clientId, bomId) {
    return get(true, `${baseUrl}/${clientId}/boms/${bomId}`);
}

export function adjustBomPricing(clientId, bomId, adjustment) {
    return post(
        true,
        `${baseUrl}/${clientId}/boms/${bomId}/adjust`,
        adjustment
    );
}

export function bulkAdjustPricingBOMs(clientId, adjustBOMs) {
    return post(
        true,
        `${baseUrl}/${clientId}/boms/bulk-bom-adjust-pricing`,
        adjustBOMs
    );
}

export function bulkCreateBoms(clientId, data) {
    return post(true, `${baseUrl}/${clientId}/boms/bulkCreate`, data);
}

export function exportBoms(clientId) {
    const query = {
        exportTypeId: 'CSV',
    };
    return post(
        true,
        `${baseUrl}/${clientId}/boms/export`,
        { download: true },
        query
    );
}

export function uploadClientFile(file, clientId) {
    return ApiService.upload(
        true,
        `${baseUrl}/${clientId}/upload-file`,
        file
    ).then(response => response);
}

export function deleteClientFile(clientId, fileId) {
    return ApiService.del(`${baseUrl}/${clientId}/delete-file/${fileId}`).then(
        response => response
    );
}

export function getSignedFileUrls(clientId) {
    return get(true, `${baseUrl}/${clientId}/generate-signed-file-urls`);
}

/// CLIENT VENDOR INVENTORY RULES ///

export function getClientVendorRelation(clientId) {
    return get(true, `${baseUrl}/${clientId}/vendorInventoryRules`);
}

export function handleClientVendorRuleRelation(clientId, vendorRuleId, remove) {
    return put(
        true,
        `${baseUrl}/${clientId}/handle-client-vendor-rule-relation/${vendorRuleId}?&remove=${remove}`
    );
}

// CLIENT VENDOR INVENTORY LOCATION RULES //

export function postNewClientVendorLocationRule(clientId, payload) {
    return post(
        true,
        `${baseUrl}/${clientId}/addVendorInventoryLocation`,
        payload
    );
}

export function updateVendorInventoryLocation(clientId, payload) {
    return put(
        true,
        `${baseUrl}/${clientId}/updateVendorInventoryLocation`,
        payload
    );
}

export function deleteVendorInventoryLocation(clientId, locationId) {
    return del(
        `${baseUrl}/${clientId}/deleteVendorInventoryLocation/${locationId}`
    );
}

export function postNewClientOrderRule(clientId, payload) {
    return post(true, `${baseUrl}/${clientId}/add-order-rule`, payload);
}

export function updateClientOrderRule(clientId, payload) {
    return put(true, `${baseUrl}/${clientId}/update-order-rule`, payload);
}

export function deleteClientOrderRule(clientId, ruleId) {
    return del(`${baseUrl}/${clientId}/delete-order-rule/${ruleId}`);
}

/**
 * Gets all properties belonging to a particular client
 * @param clientId
 * @param accessToken
 * @param query
 * @returns {Promise}
 */
export function getClientProperties(clientId, accessToken, query) {
    return get(
        true,
        `${baseUrl}/${clientId}/properties`,
        query,
        false,
        accessToken
    );
}

export function getClientName(clientId, accessToken) {
    return get(true, `${baseUrl}/${clientId}/name`, {}, false, accessToken);
}

export function getClientWorkRequestUrl(clientId, UUID) {
    return get(true, `${baseUrl}/${clientId}/workOrderRequestFormUrl/${UUID}`);
}

export function generateUtilityUrl(clientId) {
    return post(true, `${baseUrl}/${clientId}/generateUtilityUrl`);
}

export function suggest(prefix, minPrefixLength, accessToken) {
    return get(
        true,
        `${baseUrl}/suggest`,
        { prefix, minPrefixLength },
        false,
        accessToken
    );
}

export function updatePreferredContractors(clientId, preferredContractorIds) {
    return post(
        true,
        `${baseUrl}/${clientId}/updatePreferredContractors`,
        preferredContractorIds
    );
}

export function addPreferredContractor(clientId, preferredContractorId) {
    return post(true, `${baseUrl}/${clientId}/addPreferredContractor`, {
        preferredContractorId,
    });
}

export function removePreferredContractor(clientId, preferredContractorId) {
    return del(
        `${baseUrl}/${clientId}/removePreferredContractor/${preferredContractorId}`
    );
}

export async function addClientOrderRule(clientId, orderRuleId) {
    return await ApiService.put(
        true,
        `${baseUrl}/${clientId}/vendorOrderRules/rel/${orderRuleId}`
    );
}

export async function removeClientOrderRule(clientId, orderRuleId) {
    return await ApiService.del(
        `${baseUrl}/${clientId}/vendorOrderRules/rel/${orderRuleId}`
    );
}
