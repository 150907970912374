/**
 * @license
 * @copyright Copyright Motili Inc., 2021 All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';

import AdminMetaService from '../../../admin/services/AdminMetaService';
import ContractorMetaService from '../../../contractor/services/ContractorMetaService';
import ClientMetaService from '../../../client/services/ClientMetaService';
import * as HealthCheckService from '../../services/HealthCheckService';
import * as AuthService from '../../services/AuthService';
import Config from '../../../Config';

const metaStoreInitialState = {
    loading: true,
    initialized: false,
    error: null,
    activeUser: '',
    s2Version: '',
    webVersion: '',
    metaData: {
        timezones: [],
        usStates: [],
        financial: {
            terms: [],
            statuses: [],
        },
        account: {
            opsAccounts: [],
            preferenceTypes: [],
            roles: [],
            departmentTypes: [],
            types: [],
            roleTypes: [],
        },
        asset: {
            assetStatuses: [],
            photoTypes: [],
            retirementReasons: [],
        },
        client: {
            clients: [],
            statuses: [],
            settings: [],
            freightTypes: [],
            businessChannels: [],
        },
        contractor: {
            statuses: [],
            qualificationTypes: [],
            member: {
                notifications: [],
            },
            serviceTypes: {},
            certifications: {},
            credentials: {},
            clients: [],
            blocklistReasons: [],
        },
        product: {
            attributes: [],
            pricingTypes: [],
            unitOfMeasures: [],
        },
        document: {
            types: [],
            tradeTypes: [],
            photoTypes: [],
            priorityTypes: [],
            contactTypes: [],
            fulfillmentTypes: [],
            attachmentTypes: [],
            workOrder: {
                statuses: [],
                types: [],
            },
            quote: {
                statuses: [],
                types: [],
                reasons: {},
            },
            order: {
                statuses: [],
                types: [],
                contactTypes: [],
                notificationTypes: [],
                logisticsTypes: [],
                issues: [],
            },
            draft: {
                statuses: [],
            },
        },
        project: {
            contactTypes: [],
            statuses: [],
            types: [],
            scopeTypes: [],
        },
        property: {
            types: [],
            typesNew: [],
            statuses: [],
        },
        ticket: {
            providers: [],
        },
        vendor: {
            allVendor: [],
            vendors: [],
            manufacturers: [],
            statuses: [],
            integrationTypes: [],
            distributionCenter: {
                configurations: [],
            },
        },
        template: {
            types: [],
        },
    },
};

const startLoading = state => {
    state.loading = true;
};

const loadingFailed = (state, action) => {
    state.loading = false;
    state.error = action.payload;
};

const metaStoreSlice = createSlice({
    name: 'metaStore',
    initialState: metaStoreInitialState,
    reducers: {
        initStart: startLoading,
        initSuccess: (state, action) => {
            state.metaData = {
                ...state.metaData,
                ...action.payload.metaData,
            };
            state.activeUser = action.payload.activeUser;
            state.s2Version = action.payload.s2Version;
            state.webVersion = action.payload.webVersion;
            state.initialized = true;
            state.loading = false;
            state.error = null;
        },
        initFailure: loadingFailed,
        resetStore: state => {
            state.initialized = false;
        },
    },
});
export default metaStoreSlice.reducer;

export const {
    initStart,
    initSuccess,
    initFailure,
    resetStore,
} = metaStoreSlice.actions;

export const initMetaStore = () => async (dispatch, getState) => {
    try {
        dispatch(initStart());
        const currentState = getState().metaStore;
        let metaData = {};
        let activeUser = currentState.activeUser;
        const s2Info = await HealthCheckService.ping();
        const currentS2Version = s2Info?.version;
        const currentWebVersion = Config.version;
        const requireReload =
            currentState.s2Version !== currentS2Version ||
            currentState.webVersion !== currentWebVersion;
        if (AuthService.isMotiliAccount()) {
            if (
                !currentState.initialized ||
                requireReload ||
                activeUser !== 'MOTILI'
            ) {
                metaData = await AdminMetaService.init();
                activeUser = 'MOTILI';
            } else {
                metaData = await AdminMetaService.refresh();
                activeUser = 'MOTILI';
            }
        } else if (AuthService.isContractorAccount()) {
            if (
                !currentState.initialized ||
                requireReload ||
                activeUser !== 'CONTRACTOR'
            ) {
                metaData = await ContractorMetaService.init();
                activeUser = 'CONTRACTOR';
            }
        } else if (AuthService.isClientAccount()) {
            if (
                !currentState.initialized ||
                requireReload ||
                activeUser !== 'CLIENT'
            ) {
                metaData = await ClientMetaService.init();
                activeUser = 'CLIENT';
            }
        }
        return dispatch(
            initSuccess({
                metaData,
                activeUser,
                s2Version: currentS2Version,
                webVersion: currentWebVersion,
            })
        );
    } catch (err) {
        console.error(err.message);
        dispatch(initFailure(err.message));
    }
};
