/**
 * Copyright Motili Inc., 2017 All Rights Reserved
 */

import React from 'react';
import Alert from '../../../common/components/Alert';
import { Button, Image } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FormPasswordInput from '../../components/form/FormPasswordInput';
import Config from '../../../Config';

import background from '../../../images/splash2.png';
import logo from '../../../images/logo-white.png';

export default function ChangePassword(props) {
    return (
        <div style={styles.background}>
            <div style={styles.formContainer}>
                <div className='login-form' style={{ flex: 0.45 }}>
                    <div className='login-form-top'>
                        <Image
                            src={logo}
                            responsive
                            className='login-form-logo'
                        />
                    </div>
                    <div
                        className='login-form-bottom'
                        style={{ maxHeight: 'none' }}
                    >
                        {!props.passwordSuccessfullyReset && (
                            <ChangePasswordForm {...props} />
                        )}
                        {props.passwordSuccessfullyReset && (
                            <PasswordSuccessfullyReset {...props} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ChangePasswordForm(props) {
    function isFormValid() {
        return (
            !props.loading &&
            props.password &&
            props.confirmationPassword &&
            props.password === props.confirmationPassword
        );
    }

    return (
        <div>
            <TransitionGroup>
                {!!props.error && (
                    <CSSTransition timeout={250} classNames={'example'}>
                        <div className='reset-password-error-text '>
                            {_renderError(props.error)}
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
            <form
                key='resetPassword'
                onSubmit={e => props.handleSubmitPassword(e)}
                style={{ marginTop: 20 }}
            >
                <div style={{ marginBottom: 5 }}>
                    <FormPasswordInput
                        noLabel
                        placeholder={'New Password'}
                        value={props.password}
                        bsSize={'large'}
                        onChange={e => {
                            props.handlePasswordChange({
                                password: e.target.value,
                            });
                        }}
                    />
                </div>
                <div style={{ marginBottom: 5 }}>
                    <FormPasswordInput
                        noLabel
                        placeholder={'Repeat Password'}
                        value={props.confirmationPassword}
                        bsSize={'large'}
                        onChange={e => {
                            props.handlePasswordChange({
                                confirmationPassword: e.target.value,
                            });
                        }}
                    />
                </div>
                <div style={{ marginBottom: 10 }}>
                    <Button
                        bsStyle='primary'
                        bsSize='large'
                        type='submit'
                        block
                        disabled={!isFormValid()}
                    >
                        {props.loading ? 'Updating ...' : 'Update'}
                    </Button>
                </div>

                <div
                    style={styles.textLink}
                    onClick={() => props.handleGoToAccountLogin()}
                >
                    Go to account login
                </div>
            </form>
        </div>
    );
}

function PasswordSuccessfullyReset(props) {
    return (
        <div style={{ textAlign: 'center', fontSize: 20 }}>
            <div>Success! Your password has been reset.</div>
            <div style={{ marginTop: 20 }}>
                <a href={'#/login'}>Click to return to the login screen</a>
            </div>
        </div>
    );
}

function _renderError(error) {
    if (error === 'Invalid token') {
        return `${error} - Note: reset links are only valid for 24 hours, Please try reset link again`;
    }

    return error;
}

const styles = {
    background: {
        height: '100vh',
        minWidth: 768,
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
    },
    formContainer: {
        flexDirection: 'row',
        display: 'flex',
        flex: 0.75,
        justifyContent: 'space-around',
    },
    textLink: {
        cursor: 'pointer',
        color: 'white',
        fontSize: 14,
    },
    warningMessage: {
        color: Config.color.red,
        marginBottom: -15,
    },
};
