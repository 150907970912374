/**
 * Copyright Motili Inc., 2019 All Rights Reserved
 */
/* eslint-disable */
import _ from 'lodash';
import moment from 'moment-timezone';

// best email regex per stack overlords https://stackoverflow.com/questions/201323/using-a-regular-expression-to-validate-an-email-address
// const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i; // eslint-disable-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
const UNFORMATTED_PHONE_REGEX = /([0-9]{10})/;
const US_POSTAL_CODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const QR_CODE_REGEX = /^[a-zA-Z0-9\d]{11,15}$/;
const SPECIAL_CHARACTERS_CHECK_REGEX = /[!@#$%^&*(),.?":{}|<>]/;
const TAX_ID_REGEX = /^\d{3}-\d{2}-\d{4}$|^\d{2}-\d{7}$/;
const ONLY_ONE_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const VALIDATE_EMAIL_FOR_INTACCT_REGEX = /[!#$%^&*()?":{}|<>\\/]/;
const MULTIPLE_EMAIL_REGEX = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(;|,)\s*|\s*$))*$/;
const URL_REGEX = /^((http|https|ftp)\:\/\/)*([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(\/($|[a-zA-Z0-9\.\,\?\'\\\+&%\$#\=~_\-]+))*$/;

export function isPresent(val) {
    return (
        val !== null &&
        val !== undefined &&
        (val.trim ? val.trim().length > 0 : true)
    );
}

export function isString(val) {
    return _.isString(val);
}

export function isNumber(val) {
    return _.isNumber(val);
}

export function isNaN(val) {
    return _.isNaN(val);
}

export function isEmail(val) {
    return EMAIL_REGEX.test(val);
}

export function isMaxCharacters(val, max) {
    return _.size(val) <= max;
}

export function isValidQrCode(val) {
    return QR_CODE_REGEX.test(val);
}

export function isManufacturerYear(value) {
    try {
        const yearValue = parseInt(value, 10);
        const yearCurrent = moment().year();

        return yearValue ? yearValue >= 1950 && yearValue <= yearCurrent : null;
    } catch (err) {
        return false;
    }
}

export function isMotiliEmail(val) {
    if (!isEmail(val)) {
        return false;
    }
    return val.substring(val.length - '@motili.com'.length) === '@motili.com';
}

export function isPhone(val) {
    return PHONE_REGEX.test(val);
}

export function isUnformattedPhone(val) {
    return UNFORMATTED_PHONE_REGEX.test(val);
}

export function isArray(val) {
    return _.isArray(val);
}

export function isPostalCode(val) {
    return US_POSTAL_CODE_REGEX.test(val);
}

export function isDate(val) {
    return _.isDate(val);
}

export function isDateString(val) {
    if (_.isString(val)) {
        return moment(val).isValid();
    }
    return false;
}

export function isHasSpecialCharacters(val) {
    return SPECIAL_CHARACTERS_CHECK_REGEX.test(val);
}

export function isValidTaxID(val) {
    return TAX_ID_REGEX.test(val);
}

export function isMax100Characters(val) {
    return isMaxCharacters(val, 100);
}

export function isMax80Characters(val) {
    return isMaxCharacters(val, 80);
}

export function isValidContactName(val) {
    return isMaxCharacters(val, 80);
}

export function isMax40Characters(val) {
    return isMaxCharacters(val, 40);
}

export function isOnlyOneEmail(val) {
    return ONLY_ONE_EMAIL_REGEX.test(val);
}

export function isInvalidEmailForIntacct(val) {
    return VALIDATE_EMAIL_FOR_INTACCT_REGEX.test(val);
}

export function isMultipleEmail(val) {
    return MULTIPLE_EMAIL_REGEX.test(val);
}

export function isURL(val) {
    return URL_REGEX.test(val);
}
